import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useEffect, useState } from "react";

import { sortItemsByCategory } from "categories";
import LoadingComponents from "components/Loader";
import { ListNavbar } from "components/Navbar";
import AddItemPopup from "components/list/AddItemPopup";
import EditListItem from "components/list/EditListItem";
import ListCXT from "context/ListContext";
import { useFirebaseRoutesAnalytics } from "hooks/useFirebaseAnalytics";
import useListData from "hooks/useListData";
import useListIdFromParams from "hooks/useListIdFromParams";
import useUser from "hooks/useUser";
import { Item } from "types";

export default function EditListPage() {
    const { listId } = useListIdFromParams();
    const { user, loading: userLoading } = useUser();

    useFirebaseRoutesAnalytics({ listId });
    const { listData, loading, error } = useListData({ listId });

    const [listToShow, setListToShow] = useState<Array<Item>>();
    const { items: listInDB, categoriesSortOrder } = listData ?? {};

    const [parent] = useAutoAnimate({
        duration:
            listToShow?.length ?? 0 <= 15
                ? 400
                : listToShow?.length ?? 0 <= 30
                ? 600
                : 1300,
    });

    const userId = user?.userId;

    useEffect(() => {
        if (!listInDB) return;

        const sortedList = sortItemsByCategory({
            items: listInDB,
            categoriesSortOrder,
        });

        const listWithMarkedAtBottom = sortedList.toSorted(
            (firstItem: Item, secondItem: Item) => {
                const { checked: a } = firstItem;
                const { checked: b } = secondItem;
                if (a && !b) return 1;
                if (!a && b) return -1;
                return 0;
            }
        );

        setListToShow(listWithMarkedAtBottom);
    }, [listInDB, categoriesSortOrder, userId]);

    if (loading || userLoading || !listToShow)
        return <LoadingComponents fullPage />;

    if (error || !listData || !listInDB)
        return (
            <div className="flex items-center justify-center flex-col gap-5 mt-16">
                <h1 className="text-3xl font-medium">לא נמצאה רשימה</h1>
                <p className="text-lg">יש להיכנס דרך הקישור שקיבלתם בוואטסאפ</p>
            </div>
        );

    if (!userId) {
        return (
            <div className="flex pt-20 mt-16 flex-col gap-5 items-center justify-center h-full text-center text-3xl">
                <h1>לא נמצא משתמש</h1>
                <div className="text-xl">
                    יש להיכנס דרך הקישור שקיבלתם בוואטסאפ
                </div>
            </div>
        );
    }

    const listProgress =
        listInDB.filter((item) => item.checked).length / listInDB.length;

    return (
        <ListCXT listId={listId}>
            <div className="h-full relative mt-16">
                <ListNavbar
                    isEditing={true}
                    listProgress={listProgress}
                    listName={listData.name + " - עריכה"}
                    showSplitMode={false}
                />
                <div className="fixed bottom-16 right-5 z-20">
                    <AddItemPopup />
                </div>
                <ul
                    ref={parent}
                    className="flex flex-col divide-y divide-slate-50 text-2xl pt-10 w-full pb-5"
                >
                    {listToShow.length > 0 ? (
                        listToShow.map((listItem) => (
                            <EditListItem key={listItem.text} {...listItem} />
                        ))
                    ) : (
                        <div className="grid place-content-center text-2xl">
                            הרשימה ריקה
                        </div>
                    )}
                </ul>
            </div>
        </ListCXT>
    );
}
