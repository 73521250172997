import { BOT_PHONE_NUMBER } from "../constants";
import Navbar from "./Navbar";

export default function ErrorFallback() {
    return (
        <>
            <Navbar showOnList={true} />
            <div className="grid place-content-center h-[50vh]">
                <div className="flex flex-col gap-5 justify-center items-center text-center">
                    <h1 className="text-3xl font-medium ">שגיאה בהתחברות</h1>
                    <p className="text-xl">
                        יש להיכנס שוב דרך הקישור שקיבלתם בוואטסאפ
                    </p>
                    <a
                        href={encodeURI(
                            `https://wa.me/${BOT_PHONE_NUMBER}?text=אתר הרשימה`
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-xl w-full gap-2 mt-5 font-medium bg-green-500 text-white disabled:bg-slate-200 rounded-full shadow-sm px-3 py-2 flex items-center justify-center"
                    >
                        מעבר לבוט
                    </a>
                </div>
            </div>
        </>
    );
}
