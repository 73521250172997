import { Dialog, Transition } from "@headlessui/react";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import Hotjar from "@hotjar/browser";
import Link from "components/Link";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Fragment, ReactNode, useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { useSearchParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import catsOrderDemo from "../assets/images/premium-features/cats-order.webp";
import commentsDemo from "../assets/images/premium-features/comments.webp";
import inChatEditDemo from "../assets/images/premium-features/edit.webp";
import multiListsDemo from "../assets/images/premium-features/multi-lists.webp";
import premiumListDemo from "../assets/images/premium-features/premium-list.webp";
import quantitiesDemo from "../assets/images/premium-features/quantities.webp";
import savedItemsDemo from "../assets/images/premium-features/saved-items.webp";
import slashDemo from "../assets/images/premium-features/slash-commands.webp";
import splitWebDemo from "../assets/images/premium-features/split-web.webp";
import splitDemo from "../assets/images/premium-features/split.webp";
import typosDemo from "../assets/images/premium-features/typos.webp";
import whoAddedDemo from "../assets/images/premium-features/who-added-what.webp";
import { db } from "../firebase";
import useUserId from "../hooks/useUserId";
import { CheckoutCard } from "./checkout";

const siteId = 5135423;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

export const logEvent = async (
    type: string,
    userId: string | null,
    params?: any
) => {
    try {
        if (userId) {
            const userEventsDocRef = doc(db, "events", userId);

            const data = await getDoc(userEventsDocRef);
            const events = data?.data()?.events ?? [];
            return setDoc(userEventsDocRef, {
                // return setDoc(doc(db, "events", phoneNumber ?? uuid()), {
                events: [...events, { type, params, date: new Date() }],
            });
        }
    } catch (err) {
        console.error(err);
        return setDoc(doc(db, "errors", uuid()), {
            error: JSON.stringify(err),
        });
    }
};

export default function PremiumInfo() {
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showAsList, setShowAsList] = useState(false);
    const phoneNumber = searchParams.get("phone");
    const { userId } = useUserId({ phoneNumber });

    useEffect(() => {
        logEvent("landing", userId, {});
    }, [userId]);

    const premiumFeatures = [
        {
            title: "יותר רשימות",
            description:
                "בגרסת Groceroo Plus אפשר לפתוח עוד 2 רשימות נפרדות, לעבור בינהן ולנהל אותן במקביל",
            imgSrc: multiListsDemo,
            imgAlt: "multipleListsDemo",
        },
        {
            title: "יותר חכם",
            description:
                "הבוט בגרסת ה-Plus יודע להבין יותר דברים - כמו כמויות, הערות ושגיאות כתיב",
            imgSrc: quantitiesDemo,
            imgAlt: "multipleListsDemo",
        },
        {
            title: "יותר הגדרות",
            description:
                "אפשר לשמור פריטים כקבועים ולהוסיף אותם ישר, לבחור את סדר הקטגוריות לפי המחלקות בסופר שלכם ולראות מי הוסיף מה",
            imgSrc: catsOrderDemo,
            imgAlt: "multipleListsDemo",
        },
        {
            title: "בלי לחכות, בלי הגבלות",
            description:
                "גרסת Groceroo Plus עובדת מהר יותר - לא תצטרכו לחכות אף פעם ותוכלו להשתמש בכל הפקודות המהירות תמיד",
            imgSrc: slashDemo,
            imgAlt: "multipleListsDemo",
        },
        {
            title: "יותר פקודות",
            description:
                "Groceroo Plus מציע פקודות נוספות כמו עריכה בתוך הצ'אט, שליטה בכמויות ועוד כמה הפתעות",
            imgSrc: inChatEditDemo,
            imgAlt: "multipleListsDemo",
        },
        {
            title: "הרחבה לרשימה ב-₪1",
            description: (
                <>
                    תוכלו להרחיב את המנוי ככה שכל מי שברשימה יוכל להוסיף{" "}
                    <span className="font-bold ml-1">
                        כמויות והערות לפריטים,
                    </span>
                    בדיוק כמו מנוי פלוס
                </>
            ),
            imgSrc: premiumListDemo,
            imgAlt: "multipleListsDemo",
        },
        {
            title: "פריטים שמורים וקבועים",
            description:
                'קונים את אותם דברים כל שבוע? תוכלו להגדיר חלק מהפריטים ברשימה כ"קבועים" ולהוסיף אותם לרשימה בפקודה אחת',
            imgSrc: savedItemsDemo,
            imgAlt: "savedItemsDemo",
        },
        {
            title: "פיצול הרשימה ל-2",
            description:
                "הולכים כזוג לסופר? פצלו את הרשימה שלכם ל-2 וכל אחד יתנהל מול הרשימה שלו, בלי להתנגש ובלי לראות את הפריטים של השני בזמן הקנייה",
            imgSrc: splitDemo,
            imgAlt: "splitDemo",
        },
        {
            title: "פיצול בהתאמה אישית",
            description:
                "שליטה על פיצול הרשימה - אתם יכולים לבחור אילו מחלקות יופיעו לכל אחד ברשימה ולמי יהיה שייך כל חלק",
            imgSrc: splitWebDemo,
            imgAlt: "splitDemo",
        },
        {
            title: "מי הוסיף את זה?!",
            description:
                'משתמשי Plus יכולים לראות מי הוסיף כל פריט לרשימה שלהם, במקום לצעוק בכל הבית: "מי הוסיף קורנפלקס כריות?!"',
            imgSrc: whoAddedDemo,
            imgAlt: "whoAddedDemo",
        },
        // {
        //     title: "סימון פריט כחסר",
        //     description:
        //         'המוצר חסר בסופר? אין בעיה - פשוט מסמנים אותו כ"חסר" והבוט כבר ידע להתמודד עם זה',
        //     imgSrc: multipleListsDemo,
        //     imgAlt: "multipleListsDemo",
        // },
        {
            title: "הוספת הערות לפריטים",
            description:
                "ניתן להוסיף הערות לכל פריט, בתוך סוגריים שהבוט המתקדם יודע להתעלם מהן",
            imgSrc: commentsDemo,
            imgAlt: "commentsDemo",
        },
        {
            title: "תמיכה בשגיאות כתיב",
            description:
                'כתבתם "מחר" במקום "מחק"? אין בעיה, הבוט בגרסת ה-Plus מבין יותר.\nכן, הוא אפילו יבין שרציתם לסמן את העגבניות כשכתבתם "סמן עגבנייה"',
            imgSrc: typosDemo,
            imgAlt: "typosDemo",
        },
        {
            title: "חודש ניסיון בחינם לגמרי",
            description:
                "מוזמנים לנסות, הכי הרבה תאהבו. בטלו מתי שתרצו, בלי שאלות ובלי סיבוכים",
            component: (
                <div className="h-[240px] w-[160px] xs:h-[408.4px] xs:w-[216px] mx-auto flex items-center justify-center rounded-2xl bg-green-300/10 px-3">
                    <Link
                        to={`/checkout/?phone=${phoneNumber}&pay=true`}
                        className="btn-primary !text-lg"
                    >
                        מנסים עכשיו בחינם{" "}
                        <ArrowLeftIcon className="w-4 h-4 ml-1" />
                    </Link>
                </div>
            ),
        },
    ];

    return (
        <div className="pb-10 mt-4 font-assistant" dir="rtl">
            <SuccessSignUpForm
                isOpen={openSuccessPopup}
                setIsOpen={setOpenSuccessPopup}
            />
            <div className="pt-2 pb-3 flex flex-col w-full pl-4 pr-7 lg:px-20 gap-1.5">
                <div className="flex flex-col gap-10 w-full max-w-[400px] justify-between items-center mt-4">
                    <div className="flex flex-col gap-1 w-full">
                        <span className="font-heebo text-start text-green-500 font-medium text-lg">
                            יותר חזק. יותר מהיר. יותר חכם.
                        </span>
                        <h1 className="font-heebo text-4xl text-start font-semibold w-full">
                            <span className="font-poppins">Groceroo</span>
                            <div className="flex gap-2 items-center">
                                למבינים
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-8 h-8 text-amber-300"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
                                    />
                                </svg>
                                {/* <LightningBoltIcon className="w-8 h-8 text-amber-300" />{" "} */}
                            </div>
                        </h1>
                    </div>
                    <p className="font-assistant text-2xl text-gray-600 font-medium">
                        הגרסה הכי מתקדמת של Groceroo שיודע להבין יותר ולתת יותר.
                        <br />
                        אתם כבר משתמשים בו - לא תשתמשו כמו שצריך?
                        {/* <br />
                        <span className="font-semibold text-black">
                            רק ₪5.90 לחודש
                        </span> */}
                    </p>
                    <div className="flex flex-col gap-1 w-full">
                        <Link
                            to={`/checkout/?phone=${phoneNumber}&pay=true`}
                            className="btn-primary"
                        >
                            התחלת חודש ניסיון בחינם
                            {/* ₪5.90 לחודש וזה שלכם */}
                        </Link>
                        <span className="text-center text-lg text-gray-700">
                            ואחרי זה ₪5.90 לחודש
                        </span>
                    </div>
                </div>
            </div>
            <div className="mt-10 relative pt-2 pb-20">
                <button
                    className="flex gap-1.5 items-center justify-end w-full text-slate-500 px-2 pb-2"
                    onClick={() => setShowAsList((prev) => !prev)}
                >
                    הצג:{" "}
                    {!showAsList ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                            />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                            />
                        </svg>
                    )}
                </button>
                <div className="h-[500px] lg:h-[450px] xs:h-[600px] opacity-40 w-[95vw] bg-[linear-gradient(115deg,var(--tw-gradient-stops))] from-[#64e843a3] from-[28%] via-[#53da67bb] via-[70%] to-[#60f060] rounded-[2.25rem] ring-1 ring-black/5 z-0 absolute top-24 lg:top-24 right-1 sm:right-3" />
                {showAsList ? (
                    <div className="relative z-10 px-5 lg:mr-20 lg:pl-40">
                        <CheckoutCard isDiscount={false} />
                    </div>
                ) : (
                    <div className="relative py-2 z-10 flex gap-5 lg:gap-10 w-screen scorllbar lg:pb-5 px-3 sm:px-5 mobile-md:px-7 lg:pl-[4.5rem] lg:pr-[4.5rem] overflow-x-auto">
                        {premiumFeatures.map((feature) => (
                            <FeatureCard
                                key={feature.title}
                                title={feature.title}
                                description={feature.description}
                                imgSrc={feature.imgSrc}
                                imgAlt={feature.imgAlt}
                                component={feature.component}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

const FeatureCard = ({
    title,
    description,
    imgSrc,
    imgAlt,
    component,
}: {
    title: string;
    description: string | ReactNode;
    imgSrc?: string;
    imgAlt?: string;
    component?: JSX.Element;
}) => {
    return (
        <div
            className={`text-center w-64 xs:w-72 sm:w-[19rem] mobile-sm:w-[19.5rem] mobile-md:w-80 h-[500px] xs:h-[650px] flex-shrink-0 shadow-black/5 rounded-[2rem] shadow-[inset_0_0_2px_1px_#ffffff4d] p-2 ring-1 ring-black/5`}
            dir="rtl"
        >
            <div className="flex flex-col gap-8 bg-white ring-1 shadow-md ring-black/5 rounded-3xl h-full w-full p-4 xs:p-6 pb-9">
                <div className="flex flex-col gap-1">
                    <h3 className="text-2xl font-semibold">{title}</h3>
                </div>
                {imgSrc ? (
                    <img
                        className="h-[240px] w-[160px] xs:h-[408.4px] xs:w-[216px] mx-auto rounded-lg mb-0"
                        src={imgSrc}
                        alt={imgAlt}
                    />
                ) : null}
                {component ? component : null}
                <p className="text-base text-start text-slate-800 font-medium">
                    {description}
                </p>
            </div>
        </div>
    );
};

function SuccessSignUpForm({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: any;
}) {
    function closeModal() {
        setIsOpen(false);
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                dir="rtl"
                as="div"
                className="relative z-10 font-assistant"
                onClose={closeModal}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-slate-900/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white py-6 px-6 shadow-xl transition-all">
                                <Dialog.Title
                                    as="div"
                                    className="flex flex-col items-center pb-4"
                                >
                                    <button
                                        onClick={closeModal}
                                        className="self-end focus:outline-none"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-5 h-5"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18 18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                    <div className="flex gap-2 items-center justify-center">
                                        {/* <Logo /> */}
                                        <h3 className="font-semibolf text-2xl">
                                            רשמתי אותך
                                        </h3>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-8 h-8 text-green-500"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="m4.5 12.75 6 6 9-13.5"
                                            />
                                        </svg>
                                    </div>
                                </Dialog.Title>
                                {
                                    <div className="text-xl">
                                        ניידע אותך כשנתחיל להפעיל את מערכת הPlus
                                        <button
                                            onClick={closeModal}
                                            className="mt-2 text-xl px-4 py-3 w-full font-medium text-center rounded-md shadow-sm text-white bg-green-500"
                                        >
                                            תודה!
                                        </button>
                                    </div>
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
