import { LightningBoltIcon } from "@heroicons/react/outline";
import Link from "components/Link";
import LoadingComponents from "components/Loader";
import useUser from "hooks/useUser";
import { formatPhoneNumber, isPremium } from "utils";

export default function Account() {
    const { user, loading } = useUser();

    if (loading) return <LoadingComponents fullPage />;

    if (!user)
        return (
            <div className="text-red-500 text-center text-xl p-20 m-auto">
                קרתה תקלה, אנא נסה מחדש
            </div>
        );

    const { userId, userData } = user;

    const isPremiumUser = isPremium({
        type: userData?.accountType,
    });

    return (
        <div className="h-[85vh] w-full flex flex-col justify-between pb-5">
            <div className="w-full h-[75vh] flex flex-col gap-0 text-center items-center mt-2">
                <div className="w-32 h-32 rounded-full text-slate-300 flex items-center justify-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-full h-full"
                    >
                        <path
                            fillRule="evenodd"
                            d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                            clipRule="evenodd"
                        />
                    </svg>
                </div>
                <h1 className="text-3xl font-semibold">
                    {userData?.whatsappDisplayName}
                </h1>
                <h2 className="text-2xl text-slate-600">
                    {formatPhoneNumber({ phoneNumber: userId })}
                </h2>
                <h3 className="flex gap-1 text-xl mt-2">
                    חשבון{" "}
                    {isPremiumUser ? (
                        <span className="font-semibold">
                            פלוס
                            <LightningBoltIcon className="mr-1 h-5 w-5 inline-block text-amber-300" />
                        </span>
                    ) : (
                        "חינמי"
                    )}
                </h3>
            </div>
            {isPremiumUser ? (
                <a
                    className="text-center text-lg text-red-500 w-full max-w-[400px] mx-auto"
                    href={`https://wa.me/972587501168?text=${encodeURI(
                        "היי, אני מעוניינ/ת לבטל את מנוי הפלוס שלי"
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    ביטול מנוי הפלוס
                </a>
            ) : (
                <Link
                    to={`/premium-info?phone=${userId}`}
                    className="w-full max-w-[400px] mx-auto h-10 rounded-full text-lg font-medium flex items-center justify-center bg-green-500 py-2 text-center text-white"
                >
                    שדרוג החשבון
                </Link>
            )}
        </div>
    );
}
